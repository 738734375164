import React from "react"
import { withTranslation } from "react-i18next"
import "./spirometerTitle.scss"
const SpirometerTitle = props => {
  const { t } = props
  return (
    <>
      <div className="spirometerTitle">
        <div className="container">
          <div className="spirometerTitleText">
            {t("src.pages.spirometerPage.title")}
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(SpirometerTitle)
