import React from "react"
import { withTranslation } from "react-i18next"
import "./spirometer.scss"
import img1 from "../../../../assets/img/media/industries/spirometer/img-left.png"
import img2 from "../../../../assets/img/media/industries/spirometer/img-rightcut.png"

const Spirometer = props => {
  const { t } = props
  return (
    <>
      <div className="spirometer">
        <div className="container container1">
          <div className="spirometer-large">
            <div className="spirometer-text">
              <h1>{t("src.pages.spirometerPage.spirometer.title")}</h1>
              <h2>{t("src.pages.spirometerPage.spirometer.content1")}</h2>
              <h3>{t("src.pages.spirometerPage.spirometer.content2")}</h3>
            </div>
            <img className="img-left" alt="" src={img1} />
            <img className="img-right" alt="" src={img2} />
          </div>
        </div>
        <div className="container-content">
          <div className="spirometer-mobile">
            <div className="top">
              <div className="spirometer-mobile--top">
                <p>{t("src.pages.spirometerPage.spirometer.title")}</p>
                <span>{t("src.pages.spirometerPage.spirometer.content1")}</span>
                <img className="img-top" alt="" src={img2} />
              </div>
            </div>
            <div className="bot">
              <div className="spirometer-mobile--bot">
                <p>{t("src.pages.spirometerPage.spirometer.content2")}</p>
                <img className="img-bot" alt="" src={img1} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(Spirometer)
