import React from "react"
import SpirometerPage from "../components/solution/spirometer/spirometerPage"


const Spirometer = () => {
  return (
    <>
      <SpirometerPage />
    </>
  )
}

export default Spirometer
