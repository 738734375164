import React from 'react'
import Layout from "../../layout/layout.higherComponent"
import Head from "../../head"
import SpirometerTitle from './spirometerTitle/spirometerTitle'
import AcespiroApp from './acespiroApp/acespiroApp'
import SpirometerBottom from './spirometer/spirometer'

const Spirometer = () => {
    return (
        <>
          <Layout displayHeader={true}>
            <Head title="Spirometer" />
            <SpirometerTitle />
            {/* <AcespiroApp /> */}
            <SpirometerBottom />
          </Layout>
        </>
      )
}

export default Spirometer